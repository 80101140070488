import { Listbox as HUIListbox, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { REQUIRED_MESSAGE } from '@elements/forms/constants'
import { FormError } from '@elements/forms/form-error'
import { LabelRequired } from '@elements/forms/label-required'
import { classNames } from '@utils/helpers/classNameHelper'
import { DEFAULT_LISTBOX, ListboxProps } from '.'

// V2 for place into scrollable modal. It has "position: fixed" options dropdown
export const ListboxV2 = ({
  name,
  label,
  control,
  values,
  required = true,
  defaultEmpty = false,
  shrinkIfNoError = false,
  floatingLabel = true,
  hasRequiredLabel = true,
  placeholder = '',
  size = 'md',
  ...props
}: ListboxProps) => {
  if (!placeholder) placeholder = DEFAULT_LISTBOX

  const {
    field,
    formState,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...props.rules,
      required: required ? REQUIRED_MESSAGE : false,
      validate: {
        ...(defaultEmpty && required ? { defaultEmpty: (value) => value !== placeholder } : {}),
        ...props.rules?.validate,
      },
    },
    defaultValue: defaultEmpty ? placeholder : values[0]?.value || null,
    shouldUnregister: true,
  })

  const errorRef = useRef<HTMLParagraphElement>(null)
  const [paddingHeight, setPaddingHeight] = useState('20px')
  const rootRef = useRef<HTMLDivElement>(null)
  const [optionsTop, setOptionsTop] = useState(0)

  useEffect(() => {
    if (!rootRef.current) return
    const handleScroll = () => {
      if (rootRef.current) {
        let topOffset = 0
        let el: HTMLElement = rootRef.current

        // Recursively traverse up the DOM tree and accumulate scroll top values
        while (el?.parentElement) {
          topOffset += el.parentElement.scrollTop
          el = el.parentElement
        }
        setOptionsTop(topOffset)
      }
    }

    // Function to add scroll event listeners to all parent elements in the tree
    const addScrollListenersToParents = (element: HTMLElement | Window | null) => {
      if (element) {
        element.addEventListener('scroll', handleScroll)
        if ('parentElement' in element) addScrollListenersToParents(element.parentElement)
      }
    }
    addScrollListenersToParents(window)
    addScrollListenersToParents(rootRef.current.parentElement)

    // Clean up all event listeners when the component unmounts
    return () => {
      if (rootRef.current) {
        let el: HTMLElement | null = rootRef.current
        while (el) {
          el.removeEventListener('scroll', handleScroll)
          el = el.parentElement
        }
      }
    }
  })

  useEffect(() => {
    setPaddingHeight(errorRef.current?.scrollHeight ? `${errorRef.current?.scrollHeight + 16}px` : '')
  }, [errorRef.current, error])
  return (
    <HUIListbox {...field} disabled={props?.disabled || false}>
      <div
        ref={rootRef}
        className={classNames('group pb-8 relative my-0 w-full text-base', props?.className)}
        style={{
          paddingBottom: !shrinkIfNoError || !!error || (!field.value && formState.isDirty) ? paddingHeight : '',
        }}
      >
        <div className="relative w-full overflow-hidden">
          {!floatingLabel && (
            <div id={`${name}-label`} className="flex text-lg font-bold">
              {required && hasRequiredLabel ? <LabelRequired>{label}</LabelRequired> : label}
              {props?.tooltip}
            </div>
          )}
          <HUIListbox.Button
            className={classNames(
              'peer block pt-5 pb-3 w-full dark:text-white bg-transparent autofill:bg-transparent border-0 border-b border-text/50 focus:border-secondary-variant outline-none ring-0 duration-200 appearance-none',
              props?.disabled ? 'opacity-50 cursor-not-allowed' : ''
            )}
          >
            <span className="block w-full truncate text-left leading-[21px]">{field.value ?? '\u00A0'}</span>
          </HUIListbox.Button>
          {floatingLabel && (
            <label
              id={`${name}-label`}
              htmlFor={name}
              className="pointer-events-none absolute top-5 left-0 origin-[0] -translate-y-6 scale-75 text-muted duration-200 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-muted/50 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-secondary-variant"
            >
              {required && hasRequiredLabel ? <LabelRequired>{label}</LabelRequired> : label}
            </label>
          )}
        </div>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <HUIListbox.Options
            className="fixed z-50 max-h-80 w-auto min-w-[10rem] overflow-auto rounded bg-white shadow"
            style={{ transform: `translate(0px, -${optionsTop}px)` }}
          >
            {defaultEmpty && (
              <HUIListbox.Option
                key="0"
                className={'my-1 cursor-not-allowed p-3 text-muted/50'}
                value={placeholder}
                disabled={true}
              >
                <span className="block truncate leading-[21px]">{placeholder}</span>
              </HUIListbox.Option>
            )}
            {values.map((value, index) => (
              <HUIListbox.Option
                key={index + 1}
                className={({ active }) => `p-3 my-1 cursor-pointer ${active ? 'bg-background/50' : ''}`}
                value={value.value}
              >
                <span className="block truncate leading-[21px]">{value.name}</span>
              </HUIListbox.Option>
            ))}
          </HUIListbox.Options>
        </Transition>
        <FormError ref={errorRef} condition={!!error} message={error?.message} name={name} type={error?.type} />
      </div>
    </HUIListbox>
  )
}
