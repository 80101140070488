import React from 'react'
import { useController } from 'react-hook-form'
import { FormError } from '@elements/forms/form-error'
import { LabelRequired } from '@elements/forms/label-required'
import { classNames } from '@utils/helpers/classNameHelper'
import { filterEnumToStringArray } from '@utils/helpers/enum.helpers'
import { REQUIRED_RADIO_BUTTON_MESSAGE } from '../constants'
import { RadioButtonProps } from '.'

export const RadioButtonsV1 = ({
  name,
  label,
  control,
  disabled = false,
  inputs,
  className,
  width,
  defaultValue,
  tooltip,
  description,
  labelClassName = '',
}: RadioButtonProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: { required: REQUIRED_RADIO_BUTTON_MESSAGE } })

  const inputsAsArray = Array.isArray(inputs) ? inputs : filterEnumToStringArray(inputs)

  return (
    <div className={classNames('flex flex-col group relative my-4', { 'pb-8': !!error?.message }, className)}>
      {label && (
        <div className="flex">
          <p id={`${name}-label`} className={classNames('text-lg font-bold', labelClassName)}>
            <LabelRequired>{label}</LabelRequired>
          </p>
          {tooltip}
        </div>
      )}

      {description && <p className={'-mt-1 text-xs text-muted sm:text-sm'}>{description}</p>}

      {inputsAsArray
        .filter((i) => !!i)
        .map((input) => (
          <label
            key={input}
            className={classNames('inline-flex items-center py-2 md:py-3 pr-4 mr-4', {
              'min-w-[8rem]': width === 'sm',
              'min-w-[16rem]': width === 'md',
              'min-w-[24rem]': width === 'lg',
              'opacity-50 cursor-not-allowed': disabled,
            })}
          >
            <input
              {...field}
              type="radio"
              value={input}
              required
              className="peer absolute left-1 -z-10 opacity-0"
              aria-labelledby={`${name}-label`}
              data-testid={`radio-${name}-${input}`}
              disabled={disabled}
              checked={field.value === input || defaultValue === input}
            />
            <span
              className={classNames(
                'duration-200 inline-block shrink-0 mr-4 w-5 h-5 bg-white peer-checked:bg-secondary peer-checked:border-secondary rounded-full border-2 border-text outline-2 peer-focus:outline-secondary/50 outline outline-offset-2 ring-inset ring-white ring-2',
                { 'outline-error': !!error },
                { 'outline-transparent': !error }
              )}
            />{' '}
            {input}
          </label>
        ))}

      <FormError condition={!!error} message={error?.message || ''} />
    </div>
  )
}
