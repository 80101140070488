import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { Icon, Icons } from '@elements/icons/icon'
import { classNames } from '@utils/helpers/classNameHelper'
import { CheckboxProps } from '.'

export const CheckboxV1 = ({
  name,
  children,
  control,
  disabled = false,
  defaultChecked = false,
  required = true,
  className,
  innerClassName,
}: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked)

  const {
    field,
    formState: { errors },
  } = useController({ name, control, defaultValue: '', rules: { required } })

  return (
    <div className={classNames('group relative my-4', className)}>
      <label
        className={classNames(
          'inline-flex items-center py-3 pr-4 mr-4',
          disabled && 'cursor-not-allowed',
          innerClassName
        )}
      >
        <input
          {...field}
          type="checkbox"
          checked={checked}
          required={required}
          className="peer absolute left-1 -z-10 opacity-0"
          disabled={disabled}
          onChange={(e) => {
            field.onChange(e)
            setChecked(!checked)
          }}
        />
        {checked && <Icon asset={Icons.checkmark} size={18} className="absolute left-[1px] text-white" />}
        <span
          className={classNames(
            'duration-200 inline-block shrink-0 mr-4 w-5 h-5 bg-white peer-checked:bg-secondary peer-checked:border-secondary rounded border-2 border-text outline-2 peer-focus:outline-secondary/50 outline outline-offset-2',
            { 'outline-error': !!errors?.[name] },
            { 'outline-transparent': !errors?.[name] }
          )}
        />
        <span>{children}</span>
      </label>
    </div>
  )
}
