import { Alert, AlertProps } from '@mui/material'
import React, { FC } from 'react'
import { classNames } from '@utils/helpers/classNameHelper'

export type MUIAlertProps = AlertProps & {
  size?: 'md' | 'lg'
}

export const MUIAlert: FC<MUIAlertProps> = ({
  style,
  variant = 'standard',
  severity = 'info',
  children,
  size = 'lg',
  className = '',
  ...props
}) => {
  const sizeStyles = {
    md: {
      fontSize: '15px',
      '& .MuiAlert-icon': {
        fontSize: 22,
      },
    },
    lg: {
      fontSize: '16px',
      fontWeight: 600,
      '& .MuiAlert-icon': {
        fontSize: 24,
      },
    },
  }

  return (
    <Alert
      {...props}
      variant={variant}
      severity={severity}
      sx={{ ...sizeStyles[size] }}
      className={classNames(`${size}Alert`, className)}
      style={{ ...(style || {}) }}
    >
      {children}
    </Alert>
  )
}
