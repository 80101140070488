import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { ControlledFieldProps } from '../fields.interface'
import { DateTimeFieldV1 } from './datetime-field-v1'
import { DateTimeFieldV2 } from './datetime-field-v2'

interface Props {
  min?: string
  max?: string
  format?: string
}

export type DateTimeProps = ControlledFieldProps & Props

export const DateTimeField = (props: DateTimeProps) => {
  const { enableMaterialUiForms } = useFlags()
  return enableMaterialUiForms ? <DateTimeFieldV2 {...props} /> : <DateTimeFieldV1 {...props} />
}
