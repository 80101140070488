import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { REQUIRED_MESSAGE } from '@elements/forms/constants'
import { classNames } from '@utils/helpers/classNameHelper'
import { CheckboxProps as BaseCheckboxProps } from '.'

export const CheckboxV2 = ({
  name,
  children,
  control,
  disabled = false,
  defaultChecked = false,
  required = true,
  className,
  innerClassName,
}: BaseCheckboxProps) => {
  const rules = {
    ...(required && { required: REQUIRED_MESSAGE }),
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultChecked,
    shouldUnregister: true,
  })

  const getLabel = () => {
    return <span className="font-sans text-base">{children}</span>
  }

  const getCheckbox = () => {
    return (
      <Checkbox
        {...field}
        disabled={disabled}
        defaultChecked={defaultChecked}
        required={required}
        checked={field.value}
      />
    )
  }

  const getHelperText = () => {
    return <FormHelperText disabled={disabled}>{error?.message}</FormHelperText>
  }

  return (
    <FormControl className={classNames('flex my-4', className)} error={!!error} required={required}>
      <FormControlLabel
        label={getLabel()}
        control={getCheckbox()}
        className={classNames('my-4', innerClassName)}
        sx={{
          '& .MuiFormControlLabel-asterisk': {
            visibility: 'hidden',
          },
        }}
      />
      {getHelperText()}
    </FormControl>
  )
}
