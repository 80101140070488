import { InputLabel, MenuItem, Select } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import classNames from 'classnames'
import React from 'react'
import { useController } from 'react-hook-form'
import { REQUIRED_MESSAGE } from '@elements/forms/constants'
import { FormError } from '@elements/forms/fields/error'
import { ListboxProps } from '.'

export const ListboxV3 = ({
  name,
  label,
  control,
  values,
  required = true,
  defaultEmpty = false,
  shrinkIfNoError = false,
  floatingLabel = true,
  hasRequiredLabel = true,
  placeholder = '',
  size = 'md',
  errorDisplayType = 'text',
  ...props
}: ListboxProps) => {
  const sizeMap: Record<NonNullable<ListboxProps['size']>, 'small' | 'medium'> = {
    sm: 'small',
    md: 'medium',
    lg: 'medium',
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...props.rules,
      required: required ? REQUIRED_MESSAGE : false,
      validate: {
        ...props.rules?.validate,
      },
    },
    shouldUnregister: true,
    defaultValue: defaultEmpty ? placeholder : values[0]?.value || '',
  })

  return (
    <FormControl
      className={classNames('relative w-full', props.className)}
      error={!!error}
      size={sizeMap[size ?? 'md']}
      required={!!label && required}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        label={label}
        disabled={props?.disabled || false}
        onChange={(v) => field.onChange(v.target.value)}
        value={field.value || ''}
        displayEmpty={!!placeholder}
        renderValue={() => {
          if (placeholder && !field.value) return <>{placeholder}</>
          return <>{values.find((v) => v.value === field.value)?.name}</>
        }}
      >
        {defaultEmpty && !!placeholder && (
          <MenuItem value={''} disabled={true}>
            {placeholder}
          </MenuItem>
        )}
        {values.map((value, idx) => (
          <MenuItem key={idx} value={value.value}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
      <FormError error={error} errorDisplayType={errorDisplayType} />
    </FormControl>
  )
}
