import { PhoneConnections } from '@home-in/models'
import { FormControl } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import React from 'react'
import { useController } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {
  MOBILE_TEL_INVALID_MESSAGE,
  MOBILE_TEL_REGEX,
  REQUIRED_MESSAGE,
  TEL_INVALID_MESSAGE,
  TEL_REGEX,
} from '@elements/forms/constants'
import { ControlledFieldProps } from '@elements/forms/fields/fields.interface'
import { classNames } from '@utils/helpers/classNameHelper'

type TelFieldProps = ControlledFieldProps & {
  label: string
  connection?: PhoneConnections
  className?: string
  floatingLabel?: boolean
}

export const TelField = ({
  label,
  required = true,
  disabled = false,
  className,
  connection = PhoneConnections.Mobile,
  ...rest
}: TelFieldProps) => {
  const isMobileConection = connection === PhoneConnections.Mobile

  const {
    fieldState: { error },
    field,
  } = useController({
    ...rest,
    defaultValue: '',
    rules: {
      ...rest.rules,
      required: REQUIRED_MESSAGE,
      pattern: {
        value: isMobileConection ? MOBILE_TEL_REGEX : TEL_REGEX,
        message: isMobileConection ? MOBILE_TEL_INVALID_MESSAGE : TEL_INVALID_MESSAGE,
      },
      validate: isValidPhoneNumber,
    },
  })

  const getHelperText = () => {
    if (error) return error.message
  }

  const sanitizeInput = (input: string) => {
    return input.replaceAll(' ', '').replace(/^\+(610|0)/g, '+61')
  }

  return (
    <div className={classNames('relative', className)}>
      <FormControl error={!!error} required disabled={disabled} className="w-full">
        <MuiTelInput
          {...field}
          disabled={disabled}
          label={label}
          required
          defaultCountry={'AU'}
          onlyCountries={['AU']}
          disableDropdown
          error={!!error}
          onChange={(e) => {
            field.onChange(sanitizeInput(e))
          }}
          sx={{
            '& .MuiTypography-root': {
              paddingBottom: 0,
            },
          }}
          aria-labelledby={`${field.name}-label`}
          helperText={getHelperText()}
        />
      </FormControl>
    </div>
  )
}
