import React from 'react'

import { useController } from 'react-hook-form'
import { ForceController } from '@elements/forms/fields/fields.interface'

export const HiddenField = ({ name, control, defaultValue }: ForceController) => {
  const { field } = useController({ name, control, defaultValue, shouldUnregister: true })

  return <input type="hidden" {...field} />
}
