import { TextField as MUITextfield } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React from 'react'
import { useController } from 'react-hook-form'
import { classNames } from '@utils/helpers/classNameHelper'
import { REQUIRED_MESSAGE } from '../constants'
import { ControlledFieldProps } from './fields.interface'

export const TextField = ({
  name,
  control,
  label,
  required,
  requiredLabel = '',
  rules,
  className, // should we have this?
  readOnly = false,
  fullWidth = true,
  variant = 'outlined',
  type = 'text',
  helperText,
  ...props
}: ControlledFieldProps & TextFieldProps) => {
  const mergedRules = {
    ...rules,
    ...(required && {
      required: requiredLabel ? requiredLabel : REQUIRED_MESSAGE,
    }),
  }

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: mergedRules, defaultValue: '', shouldUnregister: true })

  const getHelperText = () => {
    if (error) return error.message
    if (helperText) return helperText
  }

  return (
    <div className={classNames(className)}>
      <FormControl variant={variant} className="w-full">
        <MUITextfield
          required={required}
          id={`text-${name}`}
          variant={variant}
          label={label}
          fullWidth={fullWidth}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          name={field.name}
          inputRef={field.ref}
          type={type}
          disabled={readOnly}
          error={Boolean(error)}
          helperText={getHelperText()}
          aria-labelledby={`${name}-label`}
          data-testid={`input-${name}`}
          {...props}
        />
      </FormControl>
    </div>
  )
}
