import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { ControlledFieldProps } from '@elements/forms/fields/fields.interface'
import { EmailFieldV1 } from './email-field-v1'
import { EmailFieldV2 } from './email-field-v2'
import type { TextFieldProps } from '@mui/material/TextField/TextField'
import type { LDFeatureFlag } from '@redux/reducers/app'

export const EmailField = (props: ControlledFieldProps & TextFieldProps) => {
  const { enableMaterialUiForms } = useFlags<LDFeatureFlag>()

  return enableMaterialUiForms ? <EmailFieldV2 {...props} /> : <EmailFieldV1 {...props} />
}
