import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import React, { useEffect, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import { classNames } from '@utils/helpers/classNameHelper'
import { filterEnumToStringArray } from '@utils/helpers/enum.helpers'
import { REQUIRED_RADIO_BUTTON_MESSAGE } from '../constants'
import { RadioButtonProps } from '.'

export const RadioButtonsV2 = (props: RadioButtonProps) => {
  const [helperText, setHelperText] = useState(props.description)
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: { required: REQUIRED_RADIO_BUTTON_MESSAGE },
    defaultValue: props.defaultValue,
  })

  useEffect(() => {
    if (error) {
      setHelperText(error.message)
    } else {
      setHelperText(props.description)
    }
  }, [error, props.description])

  const options = useMemo(
    () => (Array.isArray(props.inputs) ? props.inputs : filterEnumToStringArray(props.inputs)).filter((item) => !!item),
    [props.inputs]
  )

  const getLabel = () => {
    if (!props.label) return

    return (
      <>
        <FormLabel
          disabled={props.disabled}
          component="legend"
          sx={{
            '&.MuiFormLabel-root': {
              fontWeight: 700,
              color: '#00222b',
            },
          }}
        >
          {props.label}
        </FormLabel>
        {props.tooltip}
      </>
    )
  }

  const getHelperText = () => {
    if (!helperText) return

    return (
      <FormHelperText disabled={props.disabled} className="!ml-0">
        {helperText}
      </FormHelperText>
    )
  }

  const getRadioButtons = () => {
    const labelClass = classNames({
      'min-w-[8rem]': props.width === 'sm',
      'min-w-[16rem]': props.width === 'md',
      'min-w-[24rem]': props.width === 'lg',
    })

    const items = options.map((item, index) => (
      <FormControlLabel
        {...field}
        className={labelClass}
        key={index}
        value={item}
        disabled={props.disabled}
        control={<Radio disabled={props.disabled} checked={field.value === item} />}
        label={item}
      />
    ))

    return <RadioGroup className={classNames({ '!flex-row': props.width })}>{items}</RadioGroup>
  }

  return (
    <div className={classNames('my-4', props.className)}>
      <FormControl error={!!error} required>
        {getLabel()}
        {getHelperText()}
        {getRadioButtons()}
      </FormControl>
    </div>
  )
}
