import { EMAIL_REGEX } from '@home-in/models'
import React from 'react'
import { BaseFieldControlled } from '@elements/forms/fields/base-field-controlled'
import { EMAIL_INVALID_MESSAGE } from '../../constants'
import { ControlledFieldProps } from '../fields.interface'

export const EmailFieldV1 = (props: ControlledFieldProps) => {
  return (
    <BaseFieldControlled
      type="email"
      {...props}
      rules={{
        ...(props.rules as any),
        pattern: {
          value: EMAIL_REGEX,
          message: EMAIL_INVALID_MESSAGE,
        },
      }}
    />
  )
}
