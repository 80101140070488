import React, { ForwardedRef, forwardRef, HTMLInputTypeAttribute } from 'react'
import { useController } from 'react-hook-form'
import { FormError } from '@elements/forms/form-error'
import { LabelRequired } from '@elements/forms/label-required'
import { classNames } from '@utils/helpers/classNameHelper'
import { REQUIRED_MESSAGE } from '../constants'
import { BaseFieldProps, ControlledFieldProps } from './fields.interface'

const Field = forwardRef(
  (
    { type, ...props }: { type: HTMLInputTypeAttribute } & Partial<ControlledFieldProps & BaseFieldProps>,
    ref: ForwardedRef<any>
  ) => {
    return type === 'textarea' ? (
      <textarea {...props} ref={ref} data-testid={`textarea-${props.name}`} />
    ) : (
      <input type={type} {...props} ref={ref} data-testid={`input-${props.name}`} />
    )
  }
)

const Label = ({
  name,
  hasRequiredLabel,
  label,
}: {
  name: string
  hasRequiredLabel: boolean
  label: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>
}) => {
  return (
    <label
      id={`${name}-label`}
      htmlFor={name}
      className="pointer-events-none absolute top-5 left-0 origin-[0] -translate-y-6 scale-75 text-muted duration-200 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-muted/50 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-secondary-variant"
    >
      {hasRequiredLabel ? <LabelRequired>{label}</LabelRequired> : label}
    </label>
  )
}

export const BaseFieldControlled = ({
  className,
  control,
  extraBeforeContent = null,
  floatingLabel = true,
  hasRequiredLabel,
  hasReset = false,
  label,
  labelClassName = '',
  name,
  required,
  requiredLabel = '',
  rules,
  shrinkIfNoError = false,
  tooltip = null,
  type,
  ...props
}: ControlledFieldProps & BaseFieldProps) => {
  const mergedRules = {
    ...rules,
    ...(required && {
      required: requiredLabel ? requiredLabel : REQUIRED_MESSAGE,
    }),
  }

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: mergedRules, defaultValue: '', shouldUnregister: true })
  const handleReset = () => field.onChange({ target: { value: '' } })

  return (
    <div
      className={classNames(
        'group relative z-0 w-full text-base',
        { 'pb-8': !shrinkIfNoError || !!error?.message },
        className
      )}
    >
      {!floatingLabel && (
        <div id={`${name}-label`} className={classNames('flex text-lg font-bold', labelClassName)}>
          {required ? <LabelRequired>{label}</LabelRequired> : label}
          {tooltip}
        </div>
      )}
      {extraBeforeContent}

      <Field
        {...props}
        {...field}
        className={classNames(
          'duration-200 block pb-3 pt-5 w-full bg-transparent border-0 border-b appearance-none dark:text-white outline-none ring-0 focus:border-secondary-variant peer autofill:bg-transparent',
          { 'border-error': !!error },
          { 'border-text/50': !error },
          { 'border-muted-300 text-muted-300': props.readOnly }
        )}
        type={type}
        placeholder={props.placeholder || ' '}
        formNoValidate
        aria-labelledby={`${name}-label`}
      />

      {floatingLabel && (
        <>
          {tooltip || props.placeholder ? (
            <div className={'flex'}>
              <div className={labelClassName}>
                <Label name={name} hasRequiredLabel={!!hasRequiredLabel} label={label} />
              </div>
              {tooltip}
            </div>
          ) : (
            <div className={labelClassName}>
              <Label name={name} hasRequiredLabel={!!hasRequiredLabel} label={label} />
            </div>
          )}
        </>
      )}

      {hasReset && !!field.value && (
        <div
          className="pointer-events-none absolute right-2 bottom-11 h-[20px] w-[20px] cursor-pointer rounded-full bg-text text-center text-2xl leading-[20px] text-light opacity-0 duration-200 focus:pointer-events-auto focus:opacity-100 peer-focus:pointer-events-auto peer-focus:opacity-100"
          onClick={handleReset}
          tabIndex={-1}
          role="command"
          aria-label="Reset field"
        >
          <span aria-hidden="true">&times;</span>
        </div>
      )}
      <FormError condition={!!error} message={error?.message!} />
    </div>
  )
}
