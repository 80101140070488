import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { Control, UseControllerProps } from 'react-hook-form'
import { CheckboxV1 } from './checkbox-v1'
import { CheckboxV2 } from './checkbox-v2'

export interface CheckboxProps extends UseControllerProps<any> {
  name: string
  children: React.ReactNode
  control: Control<any>
  disabled?: boolean
  defaultChecked?: boolean
  required?: boolean
  className?: string
  innerClassName?: string
}

export const Checkbox = (props: CheckboxProps) => {
  const { enableMaterialUiForms } = useFlags()
  return enableMaterialUiForms ? <CheckboxV2 {...props} /> : <CheckboxV1 {...props} />
}
