import { TextFieldProps, TextareaAutosizeProps } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { LDFeatureFlag } from '@redux/reducers/app'
import { ControlledFieldProps } from '../fields.interface'
import { TextAreaFieldV1 } from './textarea-field-v1'
import { TextAreaFieldV2 } from './textarea-field-v2'

export const TextAreaField = (props: ControlledFieldProps & TextareaAutosizeProps) => {
  const { enableMaterialUiForms } = useFlags<LDFeatureFlag>()
  return enableMaterialUiForms ? <TextAreaFieldV2 {...props} /> : <TextAreaFieldV1 {...props} />
}
