import { TextareaAutosize as BaseTextArea } from '@mui/base/TextareaAutosize'
import { FormControlLabel, TextareaAutosizeProps } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { classNames } from '@utils/helpers/classNameHelper'
import { ControlledFieldProps } from '../fields.interface'

export const TextAreaFieldV2 = ({
  name,
  control,
  className,
  required = false,
  disabled = false,
  label,
  maxLength,
  defaultValue,
  hasRequiredLabel = false,
  placeholder,
  ...rest
}: ControlledFieldProps & TextareaAutosizeProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    ...rest,
    rules: {
      required,
      ...rest.rules,
    },
  })

  return (
    <div className={classNames('relative pb-8 w-full', className)}>
      <FormControlLabel
        disabled={disabled}
        className="w-full"
        label={<span className={classNames('font-sans text-sm text-muted')}>{label}</span>}
        labelPlacement="top"
        control={
          <BaseTextArea
            {...field}
            minRows={3}
            disabled={disabled}
            maxLength={maxLength}
            required={required}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className="w-full rounded border border-muted/50 p-4"
          />
        }
        sx={{
          '& .MuiFormControlLabel-asterisk': {
            ...(!hasRequiredLabel && { visibility: 'hidden' }),
            fontSize: '0.9rem',
            color: '#b71c1c',
          },
          '&.MuiFormControlLabel-root': {
            margin: 0,
          },
        }}
      />
    </div>
  )
}
