import { Combobox as HUICombobox, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { REQUIRED_MESSAGE } from '@elements/forms/constants'
import { ComboboxProps, Values } from '@elements/forms/fields/combobox'
import { FormError } from '@elements/forms/form-error'
import { LabelRequired } from '@elements/forms/label-required'
import { classNames } from '@utils/helpers/classNameHelper'

export const ComboboxV1 = <T,>({
  name,
  label,
  control,
  hasReset = false,
  allowCustom = false,
  floatingLabel = true,
  shrinkIfNoError = false,
  ...props
}: ComboboxProps<T>) => {
  const {
    field,
    formState,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...props.rules, required: true },
    shouldUnregister: true,
  })

  const errorRef = useRef<HTMLParagraphElement>(null)
  const [paddingHeight, setPaddingHeight] = useState('20px')

  useEffect(() => {
    setPaddingHeight(errorRef.current?.scrollHeight ? `${errorRef.current?.scrollHeight + 16}px` : '')
  }, [errorRef.current, error])

  const [query, setQuery] = useState('')
  const [shouldShowReset, setShouldShowReset] = useState(false)

  const filtered = (
    query === ''
      ? props.values
      : props.values.filter((values) =>
          values.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  ).slice(0, 50)

  const button = useRef<HTMLButtonElement>(null)
  const input = useRef<HTMLInputElement>(null)

  const handleReset = useCallback(() => {
    field.value = ''
    setQuery('')
    props.reset?.()
    field.onChange()
    if (input.current) input.current.value = ''
  }, [field])

  useEffect(() => {
    setShouldShowReset(hasReset && !!field.value)
  }, [field.value])

  return (
    <>
      <HUICombobox {...field}>
        {({ open }) => (
          <div
            className={classNames('group relative my-0 w-full text-base', props?.className)}
            style={{
              paddingBottom: !shrinkIfNoError || !!error || (!field.value && formState.isDirty) ? paddingHeight : '',
            }}
          >
            <div className="relative w-full overflow-hidden">
              {!floatingLabel && (
                <div id={`${name}-label`} className="flex text-lg font-bold">
                  <LabelRequired>{label}</LabelRequired>
                  {props?.tooltip}
                </div>
              )}
              <HUICombobox.Input
                ref={props.inputRef ?? input}
                role={'comobobox'}
                aria-label={label}
                className="peer block w-full appearance-none border-0 border-b border-text/50 bg-transparent pt-5 pb-3 outline-none ring-0 duration-200 autofill:bg-transparent focus:border-secondary-variant dark:text-white"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  // hacky way around this issue https://github.com/tailwindlabs/headlessui/discussions/1236
                  !open && button.current?.click()
                  if (props.onFocus) props.onFocus(e)
                }}
                {...(props.displayValue
                  ? { displayValue: props.displayValue }
                  : { displayValue: (i: Values) => i.name })}
                onChange={
                  props.onChange
                    ? props.onChange
                    : (event) => {
                        setQuery(event.target.value)
                      }
                }
                placeholder={' '}
                data-testid={`combobox-input-${name}`}
              />
              {shouldShowReset && (
                <div
                  className="pointer-events-none absolute right-2 bottom-3 h-[20px] w-[20px] cursor-pointer rounded-full bg-text text-center text-2xl leading-[20px] text-light opacity-0 duration-200 focus:pointer-events-auto focus:opacity-100 peer-focus:pointer-events-auto peer-focus:opacity-100"
                  onClick={handleReset}
                  tabIndex={-1}
                  role="command"
                  aria-label="Reset field"
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              )}
              <HUICombobox.Button ref={button} className="hidden" tabIndex={-1}></HUICombobox.Button>
              {floatingLabel && (
                <label
                  id={`${name}-label`}
                  htmlFor={name}
                  className="pointer-events-none absolute top-5 left-0 origin-[0] -translate-y-6 scale-75 text-muted duration-200 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-muted/50 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-secondary-variant"
                >
                  <LabelRequired>{label}</LabelRequired>
                </label>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <HUICombobox.Options
                className="absolute z-20 max-h-60 w-full overflow-auto rounded bg-white shadow"
                tabIndex={-1}
              >
                {props.invalidSearch && (
                  <div className="relative my-1 cursor-default select-none p-3 text-sm">
                    {props.invalidSearchMessage}
                  </div>
                )}
                {props.searching && <div className="relative my-1 cursor-default select-none p-3">Searching...</div>}
                {filtered.length === 0 && query !== '' ? (
                  allowCustom ? (
                    <HUICombobox.Option
                      key={-1}
                      className={({ active }) => `p-3 my-1 cursor-pointer ${active ? 'bg-background/50' : ''}`}
                      value={{ name: query, value: {} } as Values<T>}
                    >
                      <span className="block truncate leading-[21px]">Add &ldquo;{query}&rdquo;</span>
                    </HUICombobox.Option>
                  ) : (
                    <div className="relative my-1 cursor-default select-none p-3">Nothing found.</div>
                  )
                ) : (
                  filtered.map((value, index) => (
                    <HUICombobox.Option
                      key={index}
                      className={({ active }) => `p-3 my-1 cursor-pointer ${active ? 'bg-background/50' : ''}`}
                      value={value.value}
                    >
                      <span className="block truncate leading-[21px]">{value.name}</span>
                    </HUICombobox.Option>
                  ))
                )}
              </HUICombobox.Options>
            </Transition>
            <FormError
              ref={errorRef}
              condition={!!error}
              message={error?.message || REQUIRED_MESSAGE}
              name={name}
              type={error?.type}
            />
          </div>
        )}
      </HUICombobox>
    </>
  )
}
