import React, { ForwardedRef, forwardRef } from 'react'

interface FormErrorProps {
  condition: boolean
  message: string | undefined
  name?: string
  type?: string
}

const customErrorMessages: Record<string, Record<string, () => JSX.Element>> = {
  validate: {
    address: () => (
      <>
        *You can only add a property in the same state as your other properties. To add a property in another state, Get
        in Touch with your Buying Assistant.
      </>
    ),
  },
  validateManualAddress: {
    'manual_address.state': () => (
      <>
        *You can only add a property in the same state as your other properties. To add a property in another state, Get
        in Touch with your Buying Assistant.
      </>
    ),
  },
}

export const FormError = forwardRef((props: FormErrorProps, ref: ForwardedRef<HTMLParagraphElement>): JSX.Element => {
  return (
    <>
      {props.condition && (
        <div ref={ref} role="alert" className="absolute bottom-2 mt-2 p-0 text-sm text-error">
          {props.type && props.name && customErrorMessages?.[props.type]?.[props.name] ? (
            customErrorMessages?.[props.type]?.[props.name]?.()
          ) : (
            <p>* {props?.message}</p>
          )}
        </div>
      )}
    </>
  )
})
