import { FormHelperText } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { MUIAlert } from '@elements/alert'

export type ErrorDisplayType = 'alert' | 'text'

export interface FormErrorProps {
  error?: FieldError
  errorDisplayType?: ErrorDisplayType
}

export const FormError = ({
  error,
  errorDisplayType = 'text',
}: {
  error?: FieldError
  errorDisplayType?: 'alert' | 'text'
}) => {
  if (!error) return <></>

  return errorDisplayType === 'alert' ? (
    <MUIAlert severity="error" className="mt-4 w-full" size="md">
      {error.message}
    </MUIAlert>
  ) : (
    <FormHelperText>
      <span className="text-error">{error.message}</span>
    </FormHelperText>
  )
}
