import { EMAIL_REGEX } from '@home-in/models'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React from 'react'
import { TextField } from '@elements/forms/fields/text-field'
import { EMAIL_INVALID_MESSAGE } from '../../constants'
import { ControlledFieldProps } from '../fields.interface'

export const EmailFieldV2 = (props: ControlledFieldProps & TextFieldProps) => {
  return (
    <TextField
      {...props}
      rules={{
        ...(props.rules as any),
        pattern: {
          value: EMAIL_REGEX,
          message: EMAIL_INVALID_MESSAGE,
        },
      }}
    />
  )
}
